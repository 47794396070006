<template>
  <div class="rs-bottom-section-bar">
    <div
      v-if="$route.name === 'SurveyForm'"
      class="rs-bottom_camera"
      @click="
        $router.push({
          name: 'PhotoGallery',
        })
      "
    >
      <div class="rs-bottom_camera"><i class="light-icon-photo"></i></div>
      Photos
    </div>
    <div v-else class="rs-bottom_camera" @click="takePicture">
      <div class="rs-bottom_camera"><i class="light-icon-camera-plus"></i></div>
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { Plugins, CameraResultType, CameraSource, FilesystemDirectory, FilesystemEncoding } from '@capacitor/core';
// import { usePhotoGallery } from '@/composables/usePhotoGallery';
export default {
  data() {
    return {
      photosLocal: [],
      imgDownloadUrl: '',
    };
  },
  computed: {
    Photos: {
      get: function() {
        return this.$store.state.Photos;
      },
      set: function(newValue) {
        return this.$store.dispatch('setPhotos', newValue);
      },
    },
  },
  // setup() {
  //   const { takePhoto } = usePhotoGallery();

  //   return {
  //     takePhoto,
  //   };
  // },
  methods: {
    async takePicture() {
      const { Camera, Filesystem } = Plugins;

      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: true,
        source: CameraSource.Camera,
        resultType: CameraResultType.DataUrl,
      });
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = image.dataUrl;
      // Can be set to the src of an image now
      // imageElement.src = imageUrl;
      console.log(image, imageUrl);
      const fileName = new Date().getTime() + '.jpeg';
      const savedFileImage = {
        filepath: fileName,
        webviewPath: imageUrl,
      };
      this.Photos.push(savedFileImage);
      console.log(this.Photos);
      //FIREBASE STORAGE
      this.imgDownloadUrl = null;
      const storageRef = firebase
        .storage()
        .ref(`${savedFileImage.filepath}`)
        .putString(imageUrl, 'data_url');
      // .put(imageUrl);
      storageRef.on(
        `state_changed`,
        snapshot => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error => {
          console.log(error.message);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then(url => {
            this.imgDownloadUrl = url;
            console.log(this.imgDownloadUrl);
          });
        }
      );
    },
  },
};
</script>

<style lang="scss">
.rs-bottom-section-bar {
  display: flex;
  align-items: stretch;
  background-color: #203d4a;
  color: #ffffff;
  padding: 10px 12px;

  position: fixed;
  bottom: 0px;
  width: 100%;

  user-select: none;
}

.rs-bottom_camera {
  cursor: pointer;
  & i {
    font-size: 28px;
  }
}
</style>
